body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a{
  cursor: pointer !important;
}

.datep {
  width: 54%;
}


.react-datepicker__day-name {
  color: #FF9A19 !important;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__header {
  text-align: center;
  background-color: #ffffff;
  border-bottom: none !important;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  top: 0;
  margin-top: 0px !important;
}


.react-datepicker-popper[data-placement^=bottom] {
  padding-top: 0px !important;
}

.otpInput{
  margin: auto;
  width: 100%;
}

.notAc{
  margin-top: 15px;
}
.btn-dark:hover {
  color: #fff;
  background-color: #ba792b;
  border-color: #ba792b;
}
.cmGallery1 {
  border-radius: 16px !important;
  height: 259px !important;
  overflow: hidden;
}

.btnCenter{
  margin: auto;
  display: table;
}

.notAc a{
 color: #FF9A19 !important;
}
.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  font-weight: bold;
  background: #216ba5;
  border-radius: 0.3rem;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #64BC5F !important;
  color: #fff;
}

.enSubBtn {
  text-align: center;
}

.sdAboutLi li{
  list-style-type: disc;
  margin-left: 20px;
  padding-left: 4px;
}

.enSub {
  margin: 15px auto;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  cursor: pointer;
}

i {
  /* padding: 0px 5px; */
  margin: 0px 2px;
}

.contectNum {
  position: fixed;
  display: none;
  top: 42px;
  right: 69px;
}

.contectNum a {
  font-size: 13px;
  color: #ffffff;
}

/* .knsl-banner .knsl-banner-content {
  height: 800px;
} */

.disBtn {
  opacity: 0.6;
  pointer-events: none;
}

.cpm-180{
  padding-top: 180px !important;
}

.cmGallery{
  height: 259px;
  margin-bottom: 25px;
}

.cmGallery img{
width: 100%;
height: 100%;
}



.galleryUser{
  height:259px ;
  background-size: cover !important;
  background-position: center !important;
}

.sub-banner{
  /* background: #303d44; */
  /* margin-top: 76px; */
  /* padding: 100px; */
  background-size: cover !important;
  background-position: center !important;
}

.categoryImage{
  overflow: auto;
  display: -webkit-box !important;
}

.contq textarea {
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 15px 20px;
  border-radius: 10px;
  background-color: #FFFFFF;
  -webkit-box-shadow: 0 2px 48px 0 rgb(0 0 0 / 4%);
  box-shadow: 0 2px 48px 0 rgb(0 0 0 / 4%);
  width: 100%;
  border: 1px solid  !important;
}


@media (max-width: 500px) {
  .contectNum {
    display: block;
    position: fixed;
    top: 42px;
    right: 69px;
  }


}